import {ApiProvider} from '@hconnect/common/hooks/useApi'
import {getPlantName, Env} from '@hconnect/common/hproduce/whiteListCheck'
import {
  Notifications,
  NotificationsOptions,
  TranslationActionKey,
  TranslationKey,
  Translations,
  generateNotificationChannels
} from '@hconnect/common/notifications'
import {getTimezoneName} from '@hconnect/uikit'
import {CommonHeader, NavItem, useGuidedTours} from '@hconnect/uikit/src/lib2'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath, useNavigate, useLocation} from 'react-router-dom'

import {dfApi} from '../common/backend/apiClient'
import {APP_NAME} from '../common/constants'
import {ACTIONS, DASHBOARD, REF_LINKS} from '../common/routing'
import {usePlantIdFromRoute} from '../hooks/urlParameters/usePlantId'
import {supportedLanguages} from '../localization'
import {useAuthContext} from '../providers/AuthProvider'

const useNavItems = (plantId): NavItem[] => {
  const {t} = useTranslation()

  return useMemo(
    () =>
      plantId
        ? [
            {
              label: t('navigation.dashboard'),
              url: generatePath(DASHBOARD, {plantId}),
              dataTestId: 'nav-dashboard'
            },
            {
              label: t('navigation.actions'),
              url: generatePath(ACTIONS, {plantId}),
              dateTestId: 'nav-actions'
            },
            {
              label: t('navigation.referenceLinks'),
              url: generatePath(REF_LINKS, {plantId}),
              dateTestId: 'nav-reference-links'
            }
          ]
        : [],
    [plantId, t]
  )
}

const NOTIFICATIONS_TRANSLATION_KEYS: Translations = {
  label: {
    [TranslationKey.TODAY]: 'notifications.label.today',
    [TranslationKey.YESTERDAY]: 'notifications.label.yesterday',
    [TranslationKey.NOTIFICATIONS]: 'notifications.label.notifications',
    [TranslationKey.EMPTY]: 'notifications.label.empty',
    [TranslationKey.ERROR]: 'errors.notificationsNotFetched',
    [TranslationKey.SHOULD_DELETE]: 'notifications.label.shouldDeleteNotification'
  },
  action: {
    [TranslationActionKey.DELETE]: 'notifications.action.delete',
    [TranslationActionKey.CONFIRM_DELETE]: 'notifications.action.confirmDelete',
    [TranslationActionKey.CANCEL]: 'notifications.action.cancel'
  }
}

export const ShellHeader: React.FC = () => {
  const {logout, user, whiteList} = useAuthContext()
  const plantId = usePlantIdFromRoute()
  const navItems = useNavItems(plantId)
  const navigate = useNavigate()
  const location = useLocation()
  const {tours, tourStatuses} = useGuidedTours()
  const timeZone = getTimezoneName()
  const {i18n} = useTranslation()

  const options = useMemo<NotificationsOptions | undefined>(() => {
    const channels = generateNotificationChannels(whiteList)
    return channels
      ? {
          channels,
          countryCode: i18n.language.toUpperCase().split('-')[0]
        }
      : undefined
  }, [whiteList, i18n.language])

  return whiteList ? (
    <CommonHeader
      appName={APP_NAME}
      onLogout={logout}
      additionalActions={
        options
          ? [
              <ApiProvider key="notification-provider" secureApi={null} publicApi={dfApi}>
                <Notifications
                  options={options}
                  translations={NOTIFICATIONS_TRANSLATION_KEYS}
                  timezone={timeZone}
                />
              </ApiProvider>
            ]
          : undefined
      }
      plant={plantId ? {plantId, plantName: getPlantName(plantId)} : undefined}
      supportedLanguages={supportedLanguages}
      user={user}
      whiteList={whiteList}
      env={process.env.REACT_APP_STAGE as Env}
      navItems={navItems}
      navigate={(url) => navigate(url)}
      locationPath={location.pathname}
      enableMyTasks
      guidedTours={{tours, statuses: tourStatuses}}
      closeDropdownOnLanguageSwitch
    />
  ) : (
    <></>
  )
}
