import {RequestFunc} from '../../types'
import {ReferenceLinks} from '../../types/backend.types'

import {dfApi} from './apiClient'
import {loggingDecorator} from './decorators'

const getRefLinksImpl: RequestFunc<void, ReferenceLinks[]> = async () => {
  const {data} = await dfApi.get<ReferenceLinks[]>('/pom-questionnaires/docs-links')
  return data
}

export const getRefLinks = loggingDecorator(getRefLinksImpl)
