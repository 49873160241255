/**
 * This file contains types that are part of the backend API.
 * Changes must be made in accordance with the API.
 */
import {
  Attachment,
  AnswerStatus,
  QuestionDifficulty,
  ActionSource,
  ActionStatus,
  ActionStatusType
} from '@hconnect/common/types'

export {type Attachment, ActionStatus, type ActionStatusType}

export const LocationDtoType = {
  Area: 'area',
  Region: 'region',
  Country: 'country'
} as const

export type LocationDtoType = (typeof LocationDtoType)[keyof typeof LocationDtoType]

export interface AnswerSubmission {
  answer?: AnswerStatus
  lastSubmitted: string
}

export interface Question {
  id: string
  subQuestions: string[]
  difficulty: QuestionDifficulty
  submission?: AnswerSubmission
  attachments: Attachment[]
  guidanceLink?: string
}

export interface ReferenceLinks {
  id: string
  sectionName: string
  topicName?: string
  guidanceLink: string
  questionId?: string
}

export const ACTION_STATUS_LIST: ActionStatusType[] = Object.values(ActionStatus)

export interface ActionItemMutation {
  title: string
  description: string
  source: ActionSource
  status: ActionStatusType
  questionId?: string
  assigneeId?: string
  dueDate: string
}

export type CommentStatus = 'new' | 'edited' | 'deleted'
