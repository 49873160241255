import {DataTable, Column} from '@hconnect/uikit/src/lib2'
import type {PaginationOptions} from '@hconnect/uikit/src/lib2'
import {Link} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {RefLinks} from '../types'

type RefLinksContainerProps = {
  refLinksData: RefLinks[]
  isLoading: boolean
}

export const RefLinksContainer: React.FC<RefLinksContainerProps> = ({refLinksData, isLoading}) => {
  const {t} = useTranslation()
  const [pageNumber, changePage] = useState<number>(0)
  const [itemsPerPage, setItemsPerPage] = useState<number>(10)

  const data = refLinksData.slice(
    pageNumber * itemsPerPage,
    pageNumber * itemsPerPage + itemsPerPage
  )
  const onPaginationChange = (pageNumber: number, itemsPerPage: number) => {
    changePage(pageNumber)
    setItemsPerPage(itemsPerPage)
  }
  const paginationOptions: PaginationOptions = {
    onPaginationChange,
    page: pageNumber,
    rowsPerPage: itemsPerPage,
    totalDataLength: refLinksData?.length ?? 0
  }

  const columns: Column<RefLinks>[] = [
    {
      key: 'sectionName',
      label: t('referenceLinksPage.table.sectionName'),
      columnSx: {
        pl: {xs: 1.5, sm: 2}
      }
    },
    {
      key: 'topicName',
      label: t('referenceLinksPage.table.topicName'),
      columnSx: {
        pl: {xs: 1.5, sm: 2}
      }
    },
    {
      key: 'questionId',
      label: t('referenceLinksPage.table.questionId'),
      columnSx: {
        pl: {xs: 1.5, sm: 2}
      }
    },
    {
      key: 'guidanceLink',
      label: t('referenceLinksPage.table.guidanceLink'),
      columnSx: {
        pl: {xs: 1.5, sm: 2}
      },
      customTemplate: ({guidanceLink}) => (
        <Link
          href={guidanceLink}
          target="_blank"
          rel="noopener noreferrer"
          variant="caption"
          data-test-id="question-ref-link"
          onClick={(e) => e.stopPropagation()}
        >
          {t('referenceLinksPage.table.guidanceLink')}
        </Link>
      )
    }
  ]
  return (
    <DataTable<RefLinks>
      columns={columns}
      data={data}
      paginationOptions={paginationOptions}
      loading={isLoading}
      data-test-id="reflinks-table"
    />
  )
}
