import {PageTitle, PageContainer, CardBox} from '@hconnect/uikit/src/lib2'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {RefLinksContainer} from '../containers/RefLinksContainer'
import {useRefLinks} from '../hooks/api/useRefLinks'

export const ReferenceLinksPage: React.FC = () => {
  const {t} = useTranslation()

  const {data: dataRefLinks = [], isInitialLoading} = useRefLinks()

  return (
    <PageContainer>
      <PageTitle sx={{ml: 0}} data-test-id="page-title-refLinks">
        {t('referenceLinksPage.title')}
      </PageTitle>
      <CardBox>
        <RefLinksContainer refLinksData={dataRefLinks} isLoading={isInitialLoading} />
      </CardBox>
    </PageContainer>
  )
}
