import {PageTitle, PageContainer, buttonOnBlueSx} from '@hconnect/uikit/src/lib2'
import {Add, FileDownload} from '@mui/icons-material'
import {LoadingButton} from '@mui/lab'
import {Grid, Theme, useMediaQuery, Stack, Button, Box} from '@mui/material'
import {identity} from 'lodash'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {ActionDialogWrapper} from '../components/actionDialog/ActionDialogWrapper'
import {ActionSideCard} from '../components/actions/ActionSideCard'
import {
  ActionsPaginatedTable,
  ACTION_TABLE_COLUMNS
} from '../components/actions/ActionsPaginatedTable'
import {ActionsContainer} from '../containers/ActionsContainer'
import {FilterBar} from '../containers/FilterBar'
import {useFilteredActions} from '../hooks/api/useFilteredActions'
import {usePlantActionsDownload} from '../hooks/api/usePlantActionsDownload'
import {useActionFilters} from '../hooks/urlParameters/useActionFilters'
import {usePlantId} from '../hooks/urlParameters/usePlantId'
import {
  useTablePage,
  useTableRowsPerPage,
  useTableSortDir,
  useActionId,
  useSearchParameter
} from '../hooks/urlParameters/useSearchParameter'
import {ActionItem, ActionItemKey, PaginatedList, SearchParamGetSet} from '../types'
import {getFilteredData} from '../utils'

const stringToActionItemKey = (str?: string): ActionItemKey | undefined =>
  str && ACTION_TABLE_COLUMNS.includes(str as ActionItemKey) ? (str as ActionItemKey) : undefined

const useActionSearchParam = (): SearchParamGetSet<ActionItemKey> => {
  return useSearchParameter('tableSortKey', identity, stringToActionItemKey)
}

export const ActionsPage: React.FC = () => {
  const {t} = useTranslation()
  const [pageIndex, setPageIndex] = useTablePage()
  const [rowsPerPage, setRowsPerPage] = useTableRowsPerPage()
  const [sortKey, setSortKey] = useActionSearchParam()
  const [sortDir, setSortDir] = useTableSortDir()
  const [actionId, setActionId] = useActionId()
  const [createdAction, setCreatedAction] = useState<ActionItem>()
  const plantId = usePlantId()
  useActionFilters(() => {
    // reset page index on filter change
    setPageIndex(0)
  })

  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const {questionCategories} = useActionFilters()
  const {data: filteredActions} = useFilteredActions({
    page: pageIndex,
    rowsPerPage,
    order: sortDir,
    sortByKey: sortKey
  })
  const data = useMemo<PaginatedList<ActionItem> | undefined>(
    () => getFilteredData(questionCategories, filteredActions),
    [filteredActions, questionCategories]
  )

  const selectedAction =
    data && actionId
      ? [...data.list, createdAction].find((item) => item?.id === actionId)
      : undefined
  const isTableShown = !selectedAction || !isSmall

  const onRowClick = useCallback(
    (action: ActionItem) => {
      setActionId(action.id)
    },
    [setActionId]
  )

  const {isLoading: actionDownloadLoading, mutate: downloadActions} =
    usePlantActionsDownload(plantId)

  return (
    <PageContainer>
      <Stack
        justifyContent={'space-between'}
        pb={2}
        spacing={1}
        direction={{xs: 'column', md: 'column'}}
      >
        <PageTitle>{t('actionsPage.title')}</PageTitle>
        <FilterBar
          additionalActions={
            <LoadingButton
              variant="contained"
              onClick={() => downloadActions()}
              startIcon={<FileDownload />}
              loading={actionDownloadLoading}
              loadingPosition={'start'}
              sx={buttonOnBlueSx}
            >
              {t('button.download')}
            </LoadingButton>
          }
        />
      </Stack>
      <Grid container spacing={2}>
        {isTableShown && (
          <Grid item xs={selectedAction ? 7 : 12}>
            <ActionsContainer>
              <Box display="flex" justifyContent="flex-end" mb={1.5} px={3}>
                <ActionDialogWrapper
                  onActionCreate={(action: ActionItem) => {
                    setActionId(action.id)
                    setCreatedAction(action)
                  }}
                  opener={
                    <Button variant={'text'} startIcon={<Add />} data-test-id="add-action">
                      {t('addAction.heading')}
                    </Button>
                  }
                />
              </Box>
              <ActionsPaginatedTable
                onPageIndexChange={setPageIndex}
                pageIndex={pageIndex}
                onRowsPerPageChange={setRowsPerPage}
                rowsPerPage={rowsPerPage}
                setSortKey={setSortKey}
                sortKey={sortKey}
                onSortDirChange={setSortDir}
                sortDir={sortDir}
                onRowClick={onRowClick}
                data={data}
                isSideCardOpen={!!selectedAction}
              />
            </ActionsContainer>
          </Grid>
        )}
        {selectedAction && (
          <Grid item xs={isTableShown ? 5 : 12}>
            <ActionSideCard selectedAction={selectedAction} />
          </Grid>
        )}
      </Grid>
    </PageContainer>
  )
}
