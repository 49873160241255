export enum QueryKeyName {
  LoadUser = 'LoadUser',
  GetLocationsStats = 'GetLocationsStats',
  GetPlant = 'GetPlant',
  GetTopic = 'GetTopic',
  GetSection = 'GetSection',
  GetSections = 'GetSections',
  GetActionItems = 'GetActionItems',
  GetQuestions = 'GetQuestions',
  GetPlantQuestions = 'GetPlantQuestions',
  GetAttachmentFiles = 'GetAttachmentFiles',
  GetComments = 'GetComments',
  GetPomUsers = 'GetPomUsers',
  GetUser = 'GetUser',
  GetRefLinks = 'GetRefLinks'
}
