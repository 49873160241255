import {PLANT_VIEWS, PlantViewOption, PlantViewOptions} from '@hconnect/common/consts'
import {parameterToInt, intToParameter} from '@hconnect/common/navigation'
import {QuestionDifficulty} from '@hconnect/common/types'
import {SortDirection} from '@mui/material'
import {identity, toString, toLower} from 'lodash'
import {useCallback, useMemo} from 'react'
import {NavigateFunction, useLocation, useNavigate} from 'react-router-dom'

import {questionDifficultyFromString} from '../../common/routing'
import {SearchParamGetSetWithDefault, ActionFilterParameters} from '../../types'

interface SearchParameters extends ActionFilterParameters {
  questionDifficulty?: QuestionDifficulty
  selectedQuestionId?: string
  plantView?: PlantViewOption
  tablePage?: number // page index, starting with 0
  tableRowsPerPage?: number
  tableSortKey?: string
  tableSortDir?: SortDirection
  actionId?: string
}

export type SearchParameterKey = keyof SearchParameters

export const setSearchParam = (search: string, key: string, value?: string): string => {
  const urlParams = new URLSearchParams(search)
  if (value) {
    urlParams.set(key, value)
  } else {
    urlParams.delete(key)
  }
  return urlParams.toString()
}

export const setSearchParamToHistory = (
  navigate: NavigateFunction,
  search: string,
  key: string,
  value?: string
) => {
  navigate({search: setSearchParam(search, key, value)})
}

const useSearchParam = (key: string): string | undefined => {
  const {search} = useLocation()
  const urlParams = new URLSearchParams(search)
  return urlParams.get(key) ?? undefined
}

export const useSearchParameter = <K extends string, T = string>(
  key: K,
  toStringParam: (value?: T) => string | undefined,
  fromStringParam: (value?: string) => T | undefined,
  defaultValue?: T
): [val: T | undefined, setVal: (val?: T) => void] => {
  const navigate = useNavigate()
  const location = document.location
  const strParam = useSearchParam(key)

  const setParameter = useCallback(
    (value?: T) => {
      setSearchParamToHistory(navigate, location.search, key, toStringParam(value))
    },
    [navigate, key, location, toStringParam]
  )

  return useMemo(
    () => [fromStringParam(strParam) ?? defaultValue, setParameter],
    [fromStringParam, strParam, defaultValue, setParameter]
  )
}

export const useStringSearchParam = <T extends string>(key: T, defaultValue = '') =>
  useSearchParameter<T, string>(key, identity, identity, defaultValue)

const useSearchParamWithDefault = <T = string>(
  key: SearchParameterKey,
  toStringParam: (value?: T) => string | undefined,
  fromStringParam: (value?: string) => T | undefined,
  defaultValue: T
): SearchParamGetSetWithDefault<T> =>
  useSearchParameter(
    key,
    toStringParam,
    fromStringParam,
    defaultValue
  ) as SearchParamGetSetWithDefault<T>

export const useStringSearchParameter = (key: SearchParameterKey) =>
  useStringSearchParam<SearchParameterKey>(key)

export const useQuestionDifficultyParam = () =>
  useSearchParamWithDefault<QuestionDifficulty>(
    'questionDifficulty',
    toString,
    questionDifficultyFromString,
    QuestionDifficulty.Basic
  )

const plantViewFromString = (value: string | undefined) =>
  PLANT_VIEWS.includes(value as PlantViewOption) ? (value as PlantViewOption) : undefined

export const usePlantViewParam = () =>
  useSearchParamWithDefault<PlantViewOption>(
    'plantView',
    toString,
    plantViewFromString,
    PlantViewOptions.REGIONAL_STRUCTURE
  )

export const useTablePage = () =>
  useSearchParamWithDefault<number>('tablePage', intToParameter, parameterToInt, 0)

export const useTableRowsPerPage = () =>
  useSearchParamWithDefault<number>('tableRowsPerPage', intToParameter, parameterToInt, 50)

const stringToSortDir = (strVal?: string): SortDirection => {
  if (!strVal) {
    return false
  }
  return toLower(strVal) === 'asc' ? 'asc' : 'desc'
}

const sortDirToString = (sortDir?: SortDirection): string | undefined =>
  sortDir ? sortDir : undefined

export const useTableSortDir = () =>
  useSearchParamWithDefault<SortDirection>('tableSortDir', sortDirToString, stringToSortDir, false)

export const useActionId = () => useStringSearchParameter('actionId')
